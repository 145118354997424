
import ItemOfVouchers from '@/components/ItemOfVoucher/Index.vue'
import HotelCard from '@/components/SFSHotelCard.vue'
import Title from '@/components/SFSTitle.vue'
import StyledSFSSelectRoom from '@/styled-components/components/SFSSelectRoom'
import store from '@/store'
import { replaceStr } from '@/utils/variables'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import _ from 'lodash'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import FetchAllMixinsVue from '../Mixins/FetchAllMixins.vue'
import { StoreType } from '@/types/store'
import { PassengerType } from '@/types/passenger'
import moment from 'moment'

export default defineComponent({
  name: 'SelectRoom',
  components: { Title, ItemOfVouchers, HotelCard, StyledSFSSelectRoom },
  mixins: [FetchAllMixinsVue, LangMixinsVue],
  props: {
    secret: {
      type: String,
      default: ''
    },
    paxVoucherId: {
      type: String,
      default: ''
    }
  },
  setup() {
    const store = useStore<StoreType>()
    return {
      airline: computed(() => store.state['airline']),
      paxsGroup: computed(() => store.getters['groupPax/listGroupPax']),
      pax: computed(() => store.state.groupPax.me),
      paxsHotel: computed(() => store.state.selectRoom.paxsHotel),
      vouchers: computed(() => store.state.vouchers),
      router: useRouter()
    }
  },
  data() {
    return {
      nameVoucher: 'Hotel',
      canDownload: false,
      showCancelHotel: false,
      curPaxInfo: undefined as PassengerType | undefined,
      cancelTime: '0',
      checkinDate: null as Date | null,
      checkoutDate: null as Date | null
    }
  },
  watch: {
    airline: function () {
      this.initData()
    },
    paxsGroup: function () {
      this.initData()
    },
    pax: function () {
      this.initData()
    },
    paxsHotel: function () {
      this.initData()
    }
  },
  created() {
    this.fetchAll()
    this.fetchVouchers()
    this.initData()
  },
  methods: {
    replaceStr,
    initData() {
      if (!_.isEmpty(this.airline) && !_.isEmpty(this.paxsGroup) && !_.isEmpty(this.pax) && !_.isEmpty(this.paxsHotel)) {
        // Get current passenger info
        this.curPaxInfo = this.pax
        if (this.paxsGroup.length > 1) {
          this.curPaxInfo = _.find(this.paxsGroup, { passenger_id: parseInt(this.pax?.curPaxID || this.pax?.passenger_id) })
        }

        this.canDownload = true

        let isAfterMidNight = 0
        if (typeof this.paxsHotel.isAfterMidNight !== 'undefined') {
          isAfterMidNight = this.paxsHotel.isAfterMidNight
        }

        if (
          !_.isEmpty(this.airline?.params) &&
          this.airline?.params.enabled_cancel_hotel_mobile == '1' &&
          (_.isNull(this.paxsHotel.ws_type) || this.paxsHotel.ws_type === '')
        ) {
          if (this.paxsHotel.remainTime > 0 && isAfterMidNight === 0) {
            this.showCancelHotel = true
            this.cancelTime = String(this.paxsHotel.remainTime)
            let self = this
            setTimeout(function () {
              self.showCancelHotel = false
              self.cancelTime = '0'
            }, self.paxsHotel.remainTime * 1000)
          }
        }

        this.handleVoucherDate(this.curPaxInfo)
      }

      if (!_.isEmpty(this.paxsGroup)) {
        const filteredPax = _.find(this.paxsGroup, { passenger_id: parseInt(this.pax?.curPaxID || this.pax?.id) })
        if (filteredPax?.more_night) {
          this.paxsHotel.more_night = filteredPax.more_night
        }
      }
    },
    handleNext() {
      if (this.vouchers.find((v) => v.service_id == 4)) {
        // has taxi
        this.router.push({ path: '/taxi-service' })
        return
      }
      this.router.push({ path: '/print-services' })
      return
    },
    handleVoucherDate(paxInfo) {
      if (_.isEmpty(paxInfo)) return false

      let paxReservations: Array<any> = paxInfo?.more_night || []
      if (!paxReservations.length) {
        const paxId = paxInfo?.id || paxInfo?.passenger_id
        paxReservations = _.find(this.paxsGroup, { passenger_id: parseInt(paxId) })?.more_night || []
      }

      if (paxReservations.length) {
        const listReservation = paxReservations
          .map(({ blockdate }) => new Date(blockdate))
          .sort((a, b) => a.getTime() - b.getTime())

        this.checkinDate = moment(listReservation[0]).toDate()
        this.checkoutDate = moment(listReservation[listReservation.length - 1])
          .add(1, 'days')
          .toDate()
      }
    }
  }
})
