import * as AWS from 'aws-sdk'
import { BaseResponse } from '../types/global'
import { HTTPBaseService } from '@/utils/request'
import { FetchHotelType } from '@/types/hotel'
import store from '@/store'
import { getUser } from '@/utils/auth'

export default class Hotel extends HTTPBaseService {
  constructor(service = 'station-handler') {
    super(service)
  }

  /**
   * sfs-api
   */
  public async fetchListHotel(body: FetchHotelType): Promise<BaseResponse> {
    const { data } = await this.instance.post('mobile/hotels/list', body)
    return data
  }

  /**
   * sfs-api
   */
  public async bookHotel(body: any): Promise<BaseResponse> {
    delete body.hotel_list_page
    const { data } = await this.instance.post('mobile/hotels/book', { data: { ...body } })
    return data
  }

  /**
   * sfs-api
   */
  public async fetchMatrixHotels(body: any): Promise<BaseResponse> {
    const { data } = await this.instance.post('mobile/hotels/check-room-matrix', { data: { ...body } })
    return data
  }
}
